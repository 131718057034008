import { Box } from '@tofu/shared/ui/atoms/box'
import { TSiteFooterColumn } from './site-footer-column.types'

export const SiteFooterColumn: TSiteFooterColumn = (props) => (
  <Box
    px={[0, 0, 0, 4]}
    borderColor='grey.300'
    borderRightStyle={['none', 'none', 'none', 'solid']}
    borderRightWidth={['none', 'none', 'none', '1px']}
    borderBottomStyle={['solid', 'solid', 'solid', 'none']}
    borderBottomWidth={['1px', '1px', '1px', 'none']}
    {...props}
  />
)
