import Image from 'next/image'
import { GridItem, SimpleGrid } from '@chakra-ui/react'
import { Box } from '@tofu/shared/ui/atoms/box'
import { Text } from '@tofu/shared/ui/atoms/text'
import { Button } from '@tofu/shared/ui/atoms/button'
import { SiteFooterColumn } from '@tofu/shared/ui/atoms/site-footer-column'
import { SiteFooterHeading } from '@tofu/shared/ui/atoms/site-footer-heading'
import { SiteFooterLink } from '@tofu/shared/ui/atoms/site-footer-link'
import { environment } from '@tofu/shared/constants/environments'

import {
  AllplantsLogo,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon
} from '@tofu/shared/ui/atoms/icons'
import { Link } from '@tofu/shop/ui/atoms/link'
import { TSiteFooter } from './site-footer.types'

export const SiteFooter: TSiteFooter = () => {
  const { shop_base_url } = environment

  return (
    <Box backgroundColor='grey.100' pt={10} pb={6}>
      <SimpleGrid
        maxWidth='container.xl'
        columns={[1, 1, 1, 5]}
        margin='auto'
        px={[4, 4, 4, 0]}
        sx={{
          'div:first-of-type': {
            borderBottom: 'none'
          },
          'div:last-of-type': {
            borderRight: 'none',
            borderBottom: 'none'
          }
        }}
      >
        <SiteFooterColumn>
          <Box textAlign={['center', 'center', 'center', 'left']} mb={3}>
            <AllplantsLogo height='32px' />
          </Box>
          <SimpleGrid
            columns={2}
            mt={8}
            rowGap={6}
            columnGap={2}
            display={['none', 'none', 'none', 'grid']}
          >
            <GridItem width='76px'>
              <Image
                src='/img/GTALogo.png'
                alt='Multi award-winning great taste producer'
                height='76px'
                width='76px'
                layout='responsive'
              />
            </GridItem>
            <GridItem width='76px'>
              <Image
                src='/img/BCorpLogo.png'
                alt='Certified B Corporation'
                height='76px'
                width='76px'
                layout='responsive'
              />
            </GridItem>
            <GridItem width='76px'>
              <Image
                src='/img/LivingWageLogo.png'
                alt='London Living Wage Employer'
                height='76px'
                width='76px'
                layout='responsive'
              />
            </GridItem>
            <GridItem width='76px'>
              <Image
                src='/img/PlantPowerIcon.png'
                alt='Plant Power'
                height='76px'
                width='76px'
                layout='responsive'
              />
            </GridItem>
          </SimpleGrid>
        </SiteFooterColumn>
        <SiteFooterColumn textAlign={['left', 'center', 'center', 'left']}>
          <SiteFooterHeading>Explore</SiteFooterHeading>
          <SiteFooterLink as={Link} href={`${shop_base_url}/where-to-find-us`}>
            Where to find us
          </SiteFooterLink>
          <SiteFooterLink as={Link} href={`${shop_base_url}/veganuary`}>
            Veganuary
          </SiteFooterLink>
          <SiteFooterLink
            as={Link}
            href={`${shop_base_url}/plants-over-ultra-processed`}
          >
            Ultra Processed Food
          </SiteFooterLink>
          <SiteFooterLink as={Link} href={`${shop_base_url}/sustainability`}>
            Sustainability
          </SiteFooterLink>
          <SiteFooterLink
            as={Link}
            href={`${shop_base_url}/not-at-school-dinner`}
          >
            (Not at) school dinner
          </SiteFooterLink>
          <SiteFooterLink
            as={Link}
            href={`${shop_base_url}/referrals?situation=footer`}
          >
            Refer a Friend
          </SiteFooterLink>
          <SiteFooterLink as={Link} href={`${shop_base_url}/blog`}>
            Planted blog
          </SiteFooterLink>
          <SiteFooterLink as={Link} href={`${shop_base_url}/gifting`}>
            Gifting
          </SiteFooterLink>
          <SiteFooterLink as={Link} href={`${shop_base_url}/ambassador`}>
            Ambassadors
          </SiteFooterLink>
        </SiteFooterColumn>
        <SiteFooterColumn textAlign={['left', 'center', 'center', 'left']}>
          <SiteFooterHeading>Help</SiteFooterHeading>
          <SiteFooterLink
            as={Link}
            href='https://help.allplants.com/'
            isExternal
          >
            FAQs
          </SiteFooterLink>
          <SiteFooterLink as={Link} href={`${shop_base_url}/contact`}>
            Contact us
          </SiteFooterLink>
          <SiteFooterLink
            as={Link}
            href='https://help.allplants.com/en/articles/87'
            isExternal
          >
            Terms & privacy
          </SiteFooterLink>
          {/* 
            This link needs to be marked as external 
            so that nextjs can kick in the js script on that page
            to load the cookie policy content
          */}
          <SiteFooterLink
            isExternal={true}
            as={Link}
            href={`${shop_base_url}/cookie-policy`}
          >
            Cookie policy
          </SiteFooterLink>
        </SiteFooterColumn>
        <SiteFooterColumn textAlign={['left', 'center', 'center', 'left']}>
          <SiteFooterHeading>About</SiteFooterHeading>
          <SiteFooterLink
            as={Link}
            href='https://hello.allplants.com/careers'
            isExternal
          >
            Careers at allplants
          </SiteFooterLink>
          <SiteFooterLink
            as={Link}
            href='https://allplants2.typeform.com/to/i6ZJpG'
            isExternal
          >
            Partnerships
          </SiteFooterLink>
          <SiteFooterLink as={Link} href={`${shop_base_url}/press-and-reviews`}>
            Press & reviews
          </SiteFooterLink>
          {/* 
            This link needs to be marked as external 
            so that nextjs can kick in the js script on that page
          */}
          <SiteFooterLink
            isExternal={true}
            as={Link}
            href={`${shop_base_url}/student-discount`}
          >
            Student discount
          </SiteFooterLink>
          <SiteFooterLink
            as={Link}
            href='https://secure.gocertify.me/at/allplants/key-worker'
            isExternal
          >
            Key workers discount
          </SiteFooterLink>
          <SiteFooterLink
            as={Link}
            href='https://secure.gocertify.me/at/allplants/senior'
            isExternal
          >
            Senior discount
          </SiteFooterLink>
        </SiteFooterColumn>
        <SiteFooterColumn textAlign={['left', 'center', 'center', 'left']}>
          <SiteFooterHeading>Stay in touch</SiteFooterHeading>
          <Box>
            {/* add OptinMonster inline embed */}
            <div
              id='om-zqxmliijitbha7zsktyv-holder'
              data-testid='newsletter-sign-up'
            />
          </Box>
          <Button
            as={Link}
            href='https://www.facebook.com/allplants/'
            variant='link'
            mr={4}
          >
            <FacebookIcon height='40px' width='40px' />
          </Button>
          <Button
            as={Link}
            href='https://twitter.com/allplants'
            variant='link'
            mr={4}
          >
            <TwitterIcon height='40px' width='40px' />
          </Button>
          <Button
            as={Link}
            href='https://instagram.com/allplants'
            variant='link'
          >
            <InstagramIcon height='40px' width='40px' />
          </Button>
          <SimpleGrid
            columns={4}
            mt={8}
            columnGap={2}
            display={['grid', 'grid', 'grid', 'none']}
          >
            <GridItem width='76px' margin='auto'>
              <Image
                src='/img/GTALogo.png'
                alt='Multi award-winning great taste producer'
                height='76px'
                width='76px'
                layout='responsive'
              />
            </GridItem>
            <GridItem width='76px' margin='auto'>
              <Image
                src='/img/BCorpLogo.png'
                alt='Certified B Corporation'
                height='76px'
                width='76px'
                layout='responsive'
              />
            </GridItem>
            <GridItem width='76px' margin='auto'>
              <Image
                src='/img/LivingWageLogo.png'
                alt='London Living Wage Employer'
                height='76px'
                width='76px'
                layout='responsive'
              />
            </GridItem>
            <GridItem width='76px' margin='auto'>
              <Image
                src='/img/PlantPowerIcon.png'
                alt='Plant Power'
                height='76px'
                width='76px'
                layout='responsive'
              />
            </GridItem>
          </SimpleGrid>
        </SiteFooterColumn>
      </SimpleGrid>
      <Text
        mt={10}
        textAlign='center'
        textStyle='body-xs'
        color='grey.700'
        fontWeight='book'
      >
        &copy; Copyright {new Date().getFullYear()} Allplants Ltd. All rights
        reserved
      </Text>
    </Box>
  )
}
