import { forwardRef } from '@chakra-ui/react'
import { Text } from '@tofu/shared/ui/atoms/text'
import { TSiteFooterLink } from './site-footer-link.types'

export const SiteFooterLink: TSiteFooterLink = forwardRef((props, ref) => {
  return (
    <Text
      color='grey.700'
      my={3}
      display='block'
      fontWeight='book'
      ref={ref}
      {...props}
    />
  )
})
